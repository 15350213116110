import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Action, ActionsSubject, Store } from '@ngrx/store';
import { Observable, Subscription, of } from 'rxjs';
import { filter, map, switchMap, tap, take, first, share, catchError } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatSnackBar } from '@angular/material';
import * as mainReducers from '../../reducers/index';
import * as associationActions from '../../store/association/association.actions';
import * as measurementPointActions from '../../store/measurement-point/measurement-point.actions';
import * as deviceActions from '../../store/device/device.actions';
import { MeasurementPoint } from '../../models/measurement-point.model';
import { Association } from '../../models/association.model';
import { Device } from '../../models/device.model';
import { ApiService } from '../../services/api-service.service';
import { EndAssociationDialogComponent } from '../end-association-dialog/end-association-dialog.component';
import { AddDeviceDialogComponent } from '../add-device-dialog/add-device-dialog.component';
import { UploadDialogComponent } from '../upload-dialog/upload-dialog.component';
import { MeasurementPointDialogComponent } from '../measurement-point-dialog/measurement-point-dialog.component'
import { AlertSettingsDialogComponent } from '../alert-settings-dialog/alert-settings-dialog.component';
import * as moment from 'moment';
import { DEVICE_TYPE_VALVE, PRESSURE_UNITS, TEMPERATURE_UNITS, Utils, VALVE_CONFIRM_CMD, VALVE_CONTROL_CMD, VALVE_STATUS } from 'src/app/shared/common';
import { GlobalVariablesService } from 'src/app/shared/global-variables.service';
import { Measurement } from 'src/app/models/measurement.model';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { UpdateDevice, ValveSwitchByKerlink, ValveSwitchByLoriot } from 'src/app/store/device/device.actions';
import { AttachDeviceDialogComponent } from '../attach-device-dialog/attach-device-dialog.component';
import { AcquisitionPeriodSetupDialogComponent } from '../acquisition-period-setup-dialog/acquisition-period-setup-dialog.component';

@Component({
  selector: 'measurement-point-detail',
  templateUrl: './measurement-point-detail.component.html',
  styleUrls: ['./measurement-point-detail.component.scss']
})
export class MeasurementPointDetailComponent implements OnInit, OnChanges {
  @Input() measurementPoint: MeasurementPoint;
  
  actionsSub: Subscription
  associations$: Observable<Association[]>
  currentAssociation$: Observable<Association>
  currentDevice$: Observable<Device>
  measurements$: Observable<Measurement[]>
  assetId: string
  devicePhoto: any
  currentDevice: Device
  currentValveControlCmd: string 
  currentValveConfirmCmd: string 
  isPhotoLoading: boolean = false
  pressureUnit: string = 'bar'
  temperatureUnit: string = '°C'
  displayCommandsHistory: boolean = false

  constructor(
    private store: Store<mainReducers.State>,
    private apiService: ApiService,
    private sanitizer: DomSanitizer, 
    private dialogRef: MatDialog,
    private snackBar: MatSnackBar,
    private actionsSubject: ActionsSubject,
    public globalVariables: GlobalVariablesService
  ) {}

  ngOnChanges() {
    if(this.measurementPoint.type=='Valve' && this.measurementPoint.attachs && this.measurementPoint.attachs.length != 0) {
      this.store.select(mainReducers.selectFeatureMeasurementPointsEntities).subscribe(measurementPoints => {
        this.measurementPoint = Object.assign({}, this.measurementPoint, { attachEntities: this.measurementPoint.attachs.map(id => measurementPoints[id]) })
      })
    }
    this.currentAssociation$ = this.store.select(mainReducers.selectFeatureAssociationsEntitiesAsArrayByMeasurementPoint, { measurementPointId: this.measurementPoint.identifier })
    .pipe(
      map(associations => this.getFirstCurrentAssociation(associations)),
    )

    this.measurements$ = this.store.select(mainReducers.selectFeatureAssetsSelectedAssetId)
    .pipe(
      filter(assetId => assetId != null),
      tap(assetId => this.assetId = assetId),
      switchMap(
        assetId => {
          let from: string = moment().subtract(1, 'week').format('YYYY[-]MM[-]DD HH[:]mm[:]ss') // default
          if(this.measurementPoint.lastMeasurement && this.measurementPoint.lastMeasurement._last_update_date) {
            const LAST = moment(this.measurementPoint.lastMeasurement._last_update_date, 'YYYY-MM-DD HH:mm:ss')
            from = LAST.subtract(1, 'week').format('YYYY[-]MM[-]DD HH[:]mm[:]ss')
          } 
          return this.apiService.getMeasurements(assetId, this.measurementPoint, from)
        }
      ),
      filter(measurements => measurements.length > 0),
      share() //Limits duplicate requests between components
    )

    this.currentAssociation$.pipe(
      filter(association => (association !== null && association.device != null)),
      switchMap(association => 
        this.store.select(mainReducers.selectFeatureSingleDeviceById, { id: association.device.identifier })
      )
    ).subscribe(device => {
      this.currentDevice = device
      this.currentValveControlStep(device)
    })
    
    // Gets device photo
    this.currentAssociation$.pipe(
      filter(association => (association !== null)),
      tap(() => this.isPhotoLoading = true),
      switchMap(association =>
        this.apiService.getDevicePhoto(association.device.identifier)
      )
    ).subscribe(
      res => this.devicePhoto = this.sanitizePhotoSrc(res),
      () => this.isPhotoLoading = false
    )
  }

  ngOnInit() {
    this.measurementPoint.expandPanel = false
    this.pressureUnit = this.globalVariables.getPressureUnit() == PRESSURE_UNITS[0]?'bar':'psi'
    this.temperatureUnit = this.globalVariables.getTemperatureUnit() == TEMPERATURE_UNITS[0]?'°C':'°F'
    this.actionsSub = this.actionsSubject.subscribe(action => {
      this.actionReader(action)
    })
  }

  ngOnDestroy() {
    this.actionsSub.unsubscribe()
  }
  
  private actionReader(action: Action, error?: any) {
    switch(action.type) {
      case deviceActions.DeviceActionTypes.DownlinkWriteWakeupPeriodSuccess: {
        console.info('DownlinkWriteWakeupPeriodSuccess')
        this.store.select(mainReducers.selectFeatureSingleDeviceById, { id: this.currentDevice.identifier }).subscribe(device => {
          if(device.network.includes('loriot')) {
            console.info('Reboot device by Loriot downlink command.')
            this.apiService.rebootDeviceByLoriot(device)
          } else {
            console.info('startRequestDownlinkCommandInterval', device)
            this.startRequestDownlinkCommandInterval(device)
          }
        })
        break;
      }
    }
  }

  private timer: any
  private startRequestDownlinkCommandInterval(device: Device) {
    if(!this.timer) {
      this.timer = setInterval(
        () => this.requestDownlinkCommand(device),
        60*1000
      )
    } else {
      clearInterval(this.timer)
      this.timer = null
      this.startRequestDownlinkCommandInterval(device)
    }
  }
  
  private requestDownlinkCommand(device: Device) {
    this.apiService.getOneDeviceDownlinkCommand(device).pipe(
      filter(device => device && ('wakeUpPeriodCommand' in device))
    ).subscribe(device => {
      if((typeof device.wakeUpPeriodCommand == 'object') && ('status' in device.wakeUpPeriodCommand) && ('endDevice' in device.wakeUpPeriodCommand)) {
        console.info('Kerlink Command: ' + device.wakeUpPeriodCommand.status, device.wakeUpPeriodCommand)
        if(device.wakeUpPeriodCommand.status.includes('OK')) {
          if(!this.timer) {
            clearInterval(this.timer)
          }
          this.apiService.rebootDeviceByKerlink(device)
        } else if(device.wakeUpPeriodCommand.status.includes('KO') || device.wakeUpPeriodCommand.status.includes('CANCELLED')) {
          console.error('Kerlink downlink failed or canceled.')
          if(!this.timer) {
            clearInterval(this.timer)
          }
        }
      } else if((typeof device.wakeUpPeriodCommand == 'object') && ('status' in device.wakeUpPeriodCommand) && ('policy' in device.wakeUpPeriodCommand)) {
        console.info('Live Objects Command: ' + device.wakeUpPeriodCommand.status, device.wakeUpPeriodCommand)
        if(device.wakeUpPeriodCommand.status.includes('PROCESSED')) {
          if(!this.timer) {
            clearInterval(this.timer)
          }
          this.apiService.rebootDeviceByLiveObjects(device)
        } else if(device.wakeUpPeriodCommand.status.includes('EXPIRED')) {
          console.error('Live Objects Commands Expired.')
          if(!this.timer) {
            clearInterval(this.timer)
          }
        }
      }
    })
  }

  private getFirstCurrentAssociation(associations: Association[]): Association {
    let currentAssociations = associations.filter(association => !(association.hasOwnProperty('end')))
    if (currentAssociations.length) {
      return currentAssociations[0]
    }
    return null
  }
  
  currentValveControlStep = (device: Device) => {
    if(device && this.measurementPoint.type == DEVICE_TYPE_VALVE) {
      if(device.status) {
        if(device.status == VALVE_STATUS._1) {
          this.currentValveControlCmd = VALVE_CONTROL_CMD._1
          this.currentValveConfirmCmd = VALVE_CONFIRM_CMD._1
        } else if(device.status == VALVE_STATUS._2) {
          this.currentValveControlCmd = VALVE_CONTROL_CMD._2
          this.currentValveConfirmCmd = VALVE_CONFIRM_CMD._2
        } else if(device.status == VALVE_STATUS._3) {
          this.currentValveConfirmCmd = VALVE_CONFIRM_CMD._3
        }
      } else {
        this.currentValveControlCmd = VALVE_CONTROL_CMD._2
        this.currentValveConfirmCmd = VALVE_CONFIRM_CMD._2
      }
    }
  }

  valveControl() {    
    const valveSwitchConfirmDialog = this.dialogRef.open(ConfirmationDialogComponent, {
      width: '470px',
      data: {
        title: 'Valve Operation',
        message: (this.currentValveControlCmd==VALVE_CONTROL_CMD._1?'Are you sure you want to bleed Hight Pressure Line ? ':'Are you sure you want to reset Bumblebee valve ? ') + 'This operation may take some time.',
      }
    })

    valveSwitchConfirmDialog.afterClosed().subscribe((res: boolean) => {
      if(res && this.currentDevice && this.currentDevice.serial) {
        let switchCode = this.currentValveControlCmd==VALVE_CONTROL_CMD._1?'31':'30'
        if(this.currentDevice.network) {
          if(this.currentDevice.network.includes('kerlink')) {
            this.store.dispatch(new ValveSwitchByKerlink({ devEui: this.currentDevice.serial, switchCode: switchCode }))
          } else if(this.currentDevice.network.includes('loriot')) {
            this.store.dispatch(new ValveSwitchByLoriot({ devEui: this.currentDevice.serial, switchCode: switchCode }))
          }
        } else {
          console.info('Unknown LoRaWAN network.')
        }
      }
    })

  }

  confirmValveStatusChanged() {
    const valveStatusChangedConfirmDialog = this.dialogRef.open(ConfirmationDialogComponent, {
      width: '470px',
      data: {
        title: 'Confirm Valve Status Changed',
        message: this.currentValveConfirmCmd==VALVE_CONFIRM_CMD._1?'Are you sure Hight Pressure Line = 0 bar ?':this.currentValveConfirmCmd==VALVE_CONFIRM_CMD._2?'Are you sure Bumblebee valve is reset ?':'Are you sure High Pressure Line under pressure ?'
      }
    })

    valveStatusChangedConfirmDialog.afterClosed().subscribe((res: boolean) => {
      if(res && this.currentDevice) {
        let errorMessage: string = null 
        let statusDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        if(this.currentValveConfirmCmd==VALVE_CONFIRM_CMD._1) {
          if(this.measurementPoint.lastMeasurement.actuator == 1) {
            this.currentDevice = Object.assign({}, this.currentDevice, { status: VALVE_STATUS._2, statusDate: statusDate })
            this.currentValveControlCmd = VALVE_CONTROL_CMD._2
            this.currentValveConfirmCmd = VALVE_CONFIRM_CMD._2
          } else {
            errorMessage = 'The solenoid valve is not opened, please wait a moment.'
          }
        } else if(this.currentValveConfirmCmd==VALVE_CONFIRM_CMD._2) {
          if(this.measurementPoint.lastMeasurement.actuator == 0) {
            this.currentDevice = Object.assign({}, this.currentDevice, { status: VALVE_STATUS._3, statusDate: statusDate })
            this.currentValveConfirmCmd = VALVE_CONFIRM_CMD._3
          } else {
            errorMessage = 'The solenoid valve is not closed, please wait a moment.'
          }
        } else if(this.currentValveConfirmCmd==VALVE_CONFIRM_CMD._3) {
          this.currentDevice = Object.assign({}, this.currentDevice, { status: VALVE_STATUS._1, statusDate: statusDate })
          this.currentValveControlCmd = VALVE_CONTROL_CMD._1
          this.currentValveConfirmCmd = VALVE_CONFIRM_CMD._1
        }

        if(errorMessage == null) {
          console.log('update valve status to:' + this.currentDevice.status, this.currentDevice)
          this.store.dispatch(new UpdateDevice(this.currentDevice))
        } else {
          this.snackBar.open(errorMessage)
        }
        
      }
    })
  }

  attachDevice() {
    const attachDialog = this.dialogRef.open(AttachDeviceDialogComponent, {
      width: '450px',
      data: { entity: this.measurementPoint }
    })
    attachDialog.afterClosed().pipe(
      filter(result => result != null),
    ).subscribe(res => {
      this.store.dispatch(new measurementPointActions.AttachMeasurementPoint({ valveMeasurementPoint: res.origin, bumblebeeMeasurementPoint: res.target }))
    })
  }

  detachDevice(mp: MeasurementPoint) {
    console.log('To detach: ', mp)
    const detachDialog = this.dialogRef.open(ConfirmationDialogComponent, {
      width: '450px',
      data: {
        title: 'Detach the Bumblebee on ' + mp.name,
        message: 'Are you sure you want to detach the Bumblebee on ' + mp.name + ' from ' + this.measurementPoint.name + '?'
      }
    })
    detachDialog.afterClosed().pipe(
      filter(result => result != null)
    ).subscribe(res => {
      if(res) {
        this.store.dispatch(new measurementPointActions.DetachMeasurementPoint({ valveMeasurementPoint: this.measurementPoint, bumblebeeMeasurementPoint: mp }))
      }
    })
  }

  endAssociation() {
    let association: Association
    this.currentAssociation$.subscribe(currentAssociation => association = currentAssociation)
    
    const endAssociationDialog = this.dialogRef.open(EndAssociationDialogComponent, {
      width: '470px',
      data: association
    });

    endAssociationDialog.afterClosed().pipe(
        filter(result => result != null),
        switchMap(
          endDate => this.currentAssociation$.pipe(
            map((association: Association) => Object.assign(association, <Association>{ end: endDate })),
          )
        ),
        take(1) //important car sinon ça tourne en boucle avec le store
      ).subscribe(
        (association: Association) => { 
          this.store.dispatch(new associationActions.UpdateAssociation({association: association, measurementPointId: this.measurementPoint.identifier}))
          this.measurementPoint.lastMeasurement = null;
        }
      );
  }

  addConnectedDevice() {
    const addConnectedDeviceDialog = this.dialogRef.open(AddDeviceDialogComponent, {
      width: '450px',
      data: { entity: this.measurementPoint }
    })
    addConnectedDeviceDialog.afterClosed().pipe(
      filter(result => result != null)
    ).subscribe(
      (result: Association) => {
        this.store.dispatch(new associationActions.AddAssociation({association: result, measurementPointId: this.measurementPoint.identifier}))
        //this.currentAssociation$ = of(result)
      },
      error => console.log('erreur !')
    )
  }

  setAlerts() {
    this.dialogRef.open(AlertSettingsDialogComponent, {
      width: '800px',
      data: { action: 'edit', measurementPoint: this.measurementPoint }
    });
  }

  updateAcquisitionPeriod() {
    this.dialogRef.open(AcquisitionPeriodSetupDialogComponent, {
      width: '400px',
      data: { device: this.currentDevice }
    })
  }

  refreshCommandsHistory() {
    this.store.dispatch(new deviceActions.LoadDownlinkCommands({ device: this.currentDevice, limit: '5' }))
  }

  switchMeaGraphAndCommandsHistory() {
    this.displayCommandsHistory = !this.displayCommandsHistory
  }

  editMeasurementPoint() {
    const measurementPointDialog = this.dialogRef.open(MeasurementPointDialogComponent, {
      width: '450px',
      data: { action: 'edit', measurementPoint: this.measurementPoint }
    });

    let assetId: string
    this.store.select(mainReducers.selectFeatureAssetsSelectedAssetId).subscribe(id => assetId=id)

    measurementPointDialog.afterClosed()
    .pipe(
      filter(result => result != null)
    )
    .subscribe((result: {measurementPoint: Partial<MeasurementPoint>}) => {
      this.store.dispatch(new measurementPointActions.UpdateMeasurementPoint({ assetId: assetId, measurementPoint: result.measurementPoint }))
    });

  }

  deleteMeasurementPoint() {
    const measurementPointDialog = this.dialogRef.open(MeasurementPointDialogComponent, {
      width: '450px',
      data: { action: 'remove', measurementPoint: this.measurementPoint }
    });

    let assetId: string
    this.store.select(mainReducers.selectFeatureAssetsSelectedAssetId).subscribe(id => assetId=id)

    measurementPointDialog.afterClosed()
    .pipe(
      filter(result => result != null)
    )
    .subscribe((result: { measurementPoint:  MeasurementPoint }) => {
      this.store.dispatch(new measurementPointActions.DeleteMeasurementPoint({ assetId: assetId, measurementPoint: result.measurementPoint }))
    });
  }

  exportMeasurements(options: number) {
    let from = options == 0? moment().subtract(1, 'week').format('YYYY[-]MM[-]DD HH[:]mm[:]ss') :
                options == 1? moment().subtract(3, 'months').format('YYYY[-]MM[-]DD HH[:]mm[:]ss') : 
                 moment().subtract(1, 'year').format('YYYY[-]MM[-]DD HH[:]mm[:]ss')
    this.store.select(mainReducers.selectFeatureAssetsSelectedAssetId)
      .pipe(
        first(),
        filter(assetId => assetId != null),
        switchMap(
          assetId => this.apiService.getMeasurementsAsCsv(assetId, this.measurementPoint, from)
        ),
        filter(measurements => measurements.length > 0),
        first()
      ).subscribe(
        data => Utils.downloadCsvFile(data, 'data_mp_' + this.measurementPoint.name + "_" + from.slice(0, 10) + "_" + moment().format('YYYY[-]MM[-]DD') + ".csv",
          this.globalVariables.getPressureUnit() != PRESSURE_UNITS[0], this.globalVariables.getTemperatureUnit() != TEMPERATURE_UNITS[0]),
        error => console.log('Error downloading the file.')
      )
  }

  sanitizePhotoSrc(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.devicePhoto = this.sanitizer.bypassSecurityTrustUrl(reader.result.toString());
      this.isPhotoLoading = false
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  // resetPhoto() {}

  uploadFile(fileType = 'device-photo') {
    let device: Device
    this.currentAssociation$.subscribe(currentAssociation => 
      device = currentAssociation.device
    )

    const dialogRef = this.dialogRef.open(UploadDialogComponent, {
      width: '500px',
      data: { identifier: device.identifier, fileType: fileType }
    });

    dialogRef.afterClosed().pipe(
      filter(result => result === true),
      catchError(error => of(error)),
      switchMap(result => 
        this.apiService.getDevicePhoto(device.identifier)
      )
    ).subscribe(
      res => this.devicePhoto = this.sanitizePhotoSrc(res),
      err => console.log(err)
    )
  }

  onAlertUpdated() {
    this.store.select(mainReducers.selectFeatureAssetsSelectedAssetId)
      .pipe(
        switchMap(assetId => this.store.select(mainReducers.selectFeatureAssetsEntities).pipe(
          map(assets => assets[assetId])
        )
      )
    ).subscribe(
      asset => this.store.dispatch(new measurementPointActions.LoadMeasurementPoints({ 
          assetId: asset.identifier,
          measurementPoints: asset.measurementPoints
      }))
    )
  }

}
