import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { 
  LoadDevicesSuccess,
  LoadDevicesFailure,
  DeviceActionTypes,
  DeviceActions,
  AddDeviceSuccess,
  AddDeviceFailure,
  UpdateDeviceSuccess,
  ValveSwitchByKerlinkSuccess,
  ValveSwitchFailure,
  DownlinkWriteWakeupPeriodFailure,
  DownlinkWriteWakeupPeriodSuccess,
  LoadDownlinkCommandsSuccess,
  LoadDownlinkCommandsFailure,
  ValveSwitchByLoriotSuccess
} from './device.actions';
import { ApiService } from '../../services/api-service.service'
import { Device } from '../../models/device.model';
import { Dictionary } from '@ngrx/entity';
import { Asset } from 'src/app/models/asset.model';
import { of } from 'rxjs';
import { LRTDataDown } from 'src/app/models/loriot-data-down.model';

@Injectable()
export class DevicesEffects {

  @Effect()
  loadDevices$ = this.actions$.pipe(
    ofType(DeviceActionTypes.LoadDevices),
    map(action => action.payload),
    concatMap((assets: Dictionary<Asset>) => this.apiService.getDevices(assets)
      .pipe(
        map((devices: Device[]) => new LoadDevicesSuccess(devices)),
        catchError(error => of(new LoadDevicesFailure({ error })))
      )
    )
  );

  @Effect()
  addDevice$ = this.actions$.pipe(
    ofType(DeviceActionTypes.AddDevice),
    map(action => action.payload),
    concatMap((device: Device) => 
      this.apiService.createDevice(device)
      .pipe(
        map((device: Device) => new AddDeviceSuccess(device)),
        catchError(error => of(new AddDeviceFailure({ error })))
      )
    )
  );

  @Effect() 
  updateDevice$ = this.actions$.pipe(
    ofType(DeviceActionTypes.UpdateDevice),
    map(action => action.payload),
    concatMap((device: Device) => 
      this.apiService.updateDevice(device).pipe(
        map((device: Device) => new UpdateDeviceSuccess({ id: device.identifier, changes: device })),
        catchError(error => of(new AddDeviceFailure({ error })))
      )
    )
  )

  @Effect()
  valveSwitchByKerlink$ = this.actions$.pipe(
    ofType(DeviceActionTypes.ValveSwitchByKerlink),
    map(action => action.payload),
    concatMap((payload: { devEui: string, switchCode: string}) =>  
      this.apiService.valveSwitchByKerlink(payload.devEui, payload.switchCode).pipe(
        map((path: string) => new ValveSwitchByKerlinkSuccess({ path: path })),
        catchError(error => of(new ValveSwitchFailure({ error })))
      )
    )
  )

  @Effect()
  valveSwitchByLoriot$ = this.actions$.pipe(
    ofType(DeviceActionTypes.ValveSwitchByLoriot),
    map(action => action.payload),
    concatMap((payload: { devEui: string, switchCode: string}) =>  
      this.apiService.valveSwitchByLoriot(payload.devEui, payload.switchCode).pipe(
        map((ack: LRTDataDown) => new ValveSwitchByLoriotSuccess({ ack: ack })),
        catchError(error => of(new ValveSwitchFailure({ error })))
      )
    )
  )

  @Effect()
  downlinkWriteWakeupPeriodByLoriot$ = this.actions$.pipe(
    ofType(DeviceActionTypes.DownlinkWriteWakeupPeriodByLoriot),
    map(action => action.payload),
    concatMap((payload: { device: Device, wakeUpPeriodInSeconds: string }) =>
      this.apiService.updateDeviceAcqPeriodByLoriot(payload.device, payload.wakeUpPeriodInSeconds).pipe(
        map((device: Device) => new DownlinkWriteWakeupPeriodSuccess({ id: device.identifier, changes: device })),
        catchError(error => of(new DownlinkWriteWakeupPeriodFailure({ error })))
      )
    )
  )

  @Effect()
  downlinkWriteWakeupPeriodByKerlink$ = this.actions$.pipe(
    ofType(DeviceActionTypes.DownlinkWriteWakeupPeriodByKerlink),
    map(action => action.payload),
    concatMap((payload: { device: Device, wakeUpPeriodInSeconds: string }) =>
      this.apiService.updateDeviceAcqPeriodByKerlink(payload.device, payload.wakeUpPeriodInSeconds).pipe(
        map((device: Device) => new DownlinkWriteWakeupPeriodSuccess({ id: device.identifier, changes: device })),
        catchError(error => of(new DownlinkWriteWakeupPeriodFailure({ error })))
      )
    )
  )

  @Effect()
  downlinkWriteWakeupPeriodByLiveObjects$ = this.actions$.pipe(
    ofType(DeviceActionTypes.DownlinkWriteWakeupPeriodByLiveObjects),
    map(action => action.payload),
    concatMap((payload: { device: Device, wakeUpPeriodInSeconds: string }) =>
      this.apiService.updateDeviceAcqPeriodByLiveObjects(payload.device, payload.wakeUpPeriodInSeconds).pipe(
        map((device: Device) => new DownlinkWriteWakeupPeriodSuccess({ id: device.identifier, changes: device })),
        catchError(error => of(new DownlinkWriteWakeupPeriodFailure({ error })))
      )
    )
  )

  @Effect()
  loadDownlinkCommands$ = this.actions$.pipe(
    ofType(DeviceActionTypes.LoadDownlinkCommands),
    map(action => action.payload),
    concatMap((payload: { device: Device, from?: string, to?: string, limit?: string }) => 
      this.apiService.getDeviceDownlinkCommands(payload.device, payload.from, payload.to, payload.limit).pipe(
        map((device: Device) => new LoadDownlinkCommandsSuccess({ id: device.identifier, changes: device })),
        catchError(error => of(new LoadDownlinkCommandsFailure({ error })))
      )    
    )
  )

  constructor(
    private actions$: Actions<DeviceActions>, 
    private apiService: ApiService,
  ) {}

}
