import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Device } from 'src/app/models/device.model';
import * as mainReducers from '../../reducers/index';
import * as deviceActions from '../../store/device/device.actions'
import { ApiService } from 'src/app/services/api-service.service';

@Component({
  selector: 'app-acquisition-period-setup-dialog',
  templateUrl: './acquisition-period-setup-dialog.component.html',
  styleUrls: ['./acquisition-period-setup-dialog.component.scss']
})
export class AcquisitionPeriodSetupDialogComponent implements OnInit {
  acqPeriodSetupForm: FormGroup
  errorMessage: string = null
  device: Device

  constructor(
    public dialogRef: MatDialogRef<AcquisitionPeriodSetupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private store: Store<mainReducers.State>,
    private fb: FormBuilder,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    if(this.data && this.data.device) {
      this.device = this.data.device
    } else {
      this.errorMessage = 'Technic error: unknown device'
    }
    this.acqPeriodSetupForm = this.fb.group({
      hour: [0, [Validators.min(0), Validators.max(24)]],
      minute: [5, [Validators.min(0), Validators.max(59)]]
    })

    this.acqPeriodSetupForm.get('hour').valueChanges.subscribe(hour => {
      this.errorMessage = (hour>=0&&hour<=24)?null:'Please enter the hour between 0 and 24.'
      if(hour==24) {
        this.acqPeriodSetupForm.get('minute').setValue(0)
      }
    })
    this.acqPeriodSetupForm.get('minute').valueChanges.subscribe(minute => {
      this.errorMessage = (minute>=0&&minute<=59)?null:'Please enter the minute between 0 and 59.'
    })
  }

  cancel() {
    this.dialogRef.close()
  }

  confirm() {
    if(!this.errorMessage) {
      let delay: number = this.acqPeriodSetupForm.value.hour*60*60 + this.acqPeriodSetupForm.value.minute*60
      console.info('Acquisition period: '+this.acqPeriodSetupForm.value.hour+'H'+this.acqPeriodSetupForm.value.minute+', '+delay+' seconds')
      if(this.device.network.includes('loriot')) {
        this.store.dispatch(new deviceActions.DownlinkWriteWakeupPeriodByLoriot({ device: this.device, wakeUpPeriodInSeconds: delay.toString() }))
      } else if(this.device.network.includes('kerlink')) {
        this.store.dispatch(new deviceActions.DownlinkWriteWakeupPeriodByKerlink({ device: this.device, wakeUpPeriodInSeconds: delay.toString() }))
      } else if(this.device.network.includes('liveobjects')) {
        this.store.dispatch(new deviceActions.DownlinkWriteWakeupPeriodByLiveObjects({ device: this.device, wakeUpPeriodInSeconds: delay.toString() }))
      }
      this.dialogRef.close()
    }
  }

}
