import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';
import { LoginComponent } from './containers/login/login.component';
import { DashboardComponent } from './containers/dashboard/dashboard.component';
import { 
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatIconModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatSidenavModule,
  MatToolbarModule,
  MatListModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatPaginatorModule,
  MatDialogModule,
  MatExpansionModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MAT_DATE_FORMATS,
  DateAdapter,
  MatAutocompleteModule,
  MatProgressBarModule,
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatTableModule,
} from '@angular/material';
import { NgxUploaderModule } from 'ngx-uploader';
import { AppDateAdapter } from './shared/date-format-adapter';
import { LayoutModule } from '@angular/cdk/layout';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import * as fromAsset from './store/asset/asset.reducer';
import * as fromBvuser from './store/bvuser/bvuser.reducer';
import * as fromAdmins from './store/administrator/administrator.reducer';
import * as fromAssetOwners from './store/assets-owner/assets-owner.reducer';
import { BvuserEffects } from './store/bvuser/bvuser.effects';
import { AssetEffects } from './store/asset/asset.effects';
import { MeasurementPointsEffects } from './store/measurement-point/measurement-point.effects';
import { AssetDetailComponent } from './containers/asset-detail/asset-detail.component';
import * as fromMeasurementPoint from './store/measurement-point/measurement-point.reducer';
import { AssetDialogComponent } from './components/asset-dialog/asset-dialog.component';
import { MeasurementPointDialogComponent } from './components/measurement-point-dialog/measurement-point-dialog.component';
import * as fromAssetType from './store/asset-type/asset-type.reducer';
import { AssetTypeEffects } from './store/asset-type/asset-type.effects';
import * as fromDeviceType from './store/device-type/device-type.reducer';
import { DeviceTypeEffects } from './store/device-type/device-type.effects';
import { DeviceMaintenanceComponent } from './containers/device-maintenance/device-maintenance.component';
import * as fromDevice from './store/device/device.reducer';
import * as fromSortFilter from './store/sort-filter/sort-filter.reducer';
import { DevicesEffects } from './store/device/device.effects';
import * as fromAssociation from './store/association/association.reducer';
import { AssociationsEffects } from './store/association/association.effects';
import { MeasurementPointDetailComponent } from './components/measurement-point-detail/measurement-point-detail.component';
import { PageNotFoundComponent } from './containers/page-not-found/page-not-found.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { EndAssociationDialogComponent } from './components/end-association-dialog/end-association-dialog.component';
import { AddDeviceDialogComponent } from './components/add-device-dialog/add-device-dialog.component';
import { AssetMapComponent } from './components/asset-map/asset-map.component';
import { MeasurementsChartComponent } from './components/measurements-chart/measurements-chart.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { UploadDialogComponent } from './components/upload-dialog/upload-dialog.component';
import { AlertSettingsDialogComponent } from './components/alert-settings-dialog/alert-settings-dialog.component';
import { AssetFilterBarComponent } from './components/asset-filter-bar/asset-filter-bar.component';
import { AlertIndicatorComponent } from './components/alert-indicator/alert-indicator.component';
import { BatteryIndicatorComponent } from './components/battery-indicator/battery-indicator.component';
import { MeasurementPointFilterBarComponent } from './components/measurement-point-filter-bar/measurement-point-filter-bar.component';
import { AssetLineComponent } from './components/asset-line/asset-line.component';
import * as fromAlertType from './store/alert-type/alert-type.reducer';
import { AlertTypeEffects } from './store/alert-type/alert-type.effects';
import * as fromAlert from './store/alert/alert.reducer';
import { AlertAcknowledgeDialogComponent } from './components/alert-acknowledge-dialog/alert-acknowledge-dialog.component';
import { AdministratorsComponent } from './containers/administrators/administrators.component';
import { TechniciansComponent } from './containers/technicians/technicians.component';
import { AccountFilterBarComponent } from './components/account-filter-bar/account-filter-bar.component';
import { AdminLineComponent } from './components/admin-line/admin-line.component';
import { AdministratorsEffects } from './store/administrator/administrator.effects';
import { AssetsOwnersEffects } from './store/assets-owner/assets-owner.effects';
import { AssetOwnerLineComponent } from './components/asset-owner-line/asset-owner-line.component';
import { UserDialogComponent } from './components/user-dialog/user-dialog.component';
import { MeasurementsChartWindowComponent } from './components/measurements-chart-window/measurements-chart-window.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { SettingsComponent } from './containers/settings/settings.component';
import { OrganizationComponent } from './containers/organization/organization.component';
import { OrganizationLineComponent } from './components/organization-line/organization-line.component';
import { OrganizationDialogComponent } from './components/organization-dialog/organization-dialog.component';
import { DeviceDialogComponent } from './components/device-dialog/device-dialog.component';
import { AttachDeviceDialogComponent } from './components/attach-device-dialog/attach-device-dialog.component';
import { AcquisitionPeriodSetupDialogComponent } from './components/acquisition-period-setup-dialog/acquisition-period-setup-dialog.component';
import { DeviceCommandTableComponent } from './components/device-command-table/device-command-table.component';

export const MY_FORMATS = {
  parse: {
      dateInput: 'YYYY-MM-DD'
  },
  display: {
      dateInput: 'input',
      monthYearLabel: {year: 'numeric', month: 'short'},
      dateA11yLabel: {year: 'numeric', month: 'short', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'},
  }
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    ToolbarComponent,
    AssetDetailComponent,
    AssetDialogComponent,
    MeasurementPointDialogComponent,
    DeviceMaintenanceComponent,
    MeasurementPointDetailComponent,
    PageNotFoundComponent,
    ConfirmationDialogComponent,
    EndAssociationDialogComponent,
    AddDeviceDialogComponent,
    AssetMapComponent,
    MeasurementsChartComponent,
    SearchBarComponent,
    UploadDialogComponent,
    AlertSettingsDialogComponent,
    AssetFilterBarComponent,
    AlertIndicatorComponent,
    BatteryIndicatorComponent,
    MeasurementPointFilterBarComponent,
    AssetLineComponent,
    AlertAcknowledgeDialogComponent,
    AdministratorsComponent,
    TechniciansComponent,
    AccountFilterBarComponent,
    AdminLineComponent,
    AssetOwnerLineComponent,
    UserDialogComponent,
    MeasurementsChartWindowComponent,
    SettingsComponent,
    OrganizationComponent,
    OrganizationLineComponent,
    OrganizationDialogComponent,
    DeviceDialogComponent,
    AttachDeviceDialogComponent,
    AcquisitionPeriodSetupDialogComponent,
    DeviceCommandTableComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([AppEffects]),
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatTableModule,
    LayoutModule,
    NgxUploaderModule,
    HighchartsChartModule,
    // Store Features
    StoreModule.forFeature('alert', fromAlert.reducer),
    StoreModule.forFeature('alertType', fromAlertType.reducer),
    StoreModule.forFeature('admins', fromAdmins.reducer),
    StoreModule.forFeature('assets', fromAsset.reducer),
    StoreModule.forFeature('assetType', fromAssetType.reducer),
    StoreModule.forFeature('assetOwners', fromAssetOwners.reducer),
    StoreModule.forFeature('associations', fromAssociation.reducer),
    StoreModule.forFeature('bvuser', fromBvuser.reducer),
    StoreModule.forFeature('devices', fromDevice.reducer),
    StoreModule.forFeature('deviceType', fromDeviceType.reducer),
    StoreModule.forFeature('measurementPoints', fromMeasurementPoint.reducer),
    StoreModule.forFeature('sortFilter', fromSortFilter.reducer),
    // Effects
    EffectsModule.forFeature([
      AdministratorsEffects,
      AlertTypeEffects,
      AssetEffects,
      AssetsOwnersEffects,
      AssetTypeEffects, 
      AssociationsEffects, 
      BvuserEffects, 
      DevicesEffects, 
      DeviceTypeEffects,
      MeasurementPointsEffects,
    ]),
  ],
  entryComponents: [
    AssetDialogComponent,
    UserDialogComponent,
    DeviceDialogComponent,
    OrganizationDialogComponent,
    MeasurementPointDialogComponent,
    ConfirmationDialogComponent,
    EndAssociationDialogComponent,
    AddDeviceDialogComponent,
    UploadDialogComponent,
    AttachDeviceDialogComponent,
    AlertSettingsDialogComponent,
    AlertAcknowledgeDialogComponent,
    MeasurementsChartWindowComponent, 
    AcquisitionPeriodSetupDialogComponent
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
