import { Component, Input, NgZone, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Action, ActionsSubject, Store } from '@ngrx/store';
import { GenericCommand } from 'src/app/models/device-command.model';
import * as mainReducers from '../../reducers/index';
import { filter } from 'rxjs/operators';
import { Device } from 'src/app/models/device.model';
import * as deviceActions from '../../store/device/device.actions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'device-command-table',
  templateUrl: './device-command-table.component.html',
  styleUrls: ['./device-command-table.component.scss']
})
export class DeviceCommandTableComponent implements OnInit {
  @Input() device: Device

  actionsSub: Subscription = new Subscription()
  dataSource: MatTableDataSource<GenericCommand>
  // Generic columns
  displayedColumns: string[] = ['created', 'payload', 'status']

  constructor(
    private actionsListener$: ActionsSubject,
    private store: Store<mainReducers.State>,
    private zone: NgZone
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource<GenericCommand>([])
    this.store.dispatch(new deviceActions.LoadDownlinkCommands({ device: this.device, limit: '5' }))
    this.actionsSub = this.actionsListener$.subscribe(action => {
      this.actionReader(action)
    })
  }
  
  ngOnDestroy() {
    this.actionsSub.unsubscribe()
  }

  private actionReader(action: Action, error?: any) {
    switch(action.type) {
      case deviceActions.DeviceActionTypes.LoadDownlinkCommandsSuccess: {
        this.store.select(mainReducers.selectFeatureSingleDeviceById, { id: this.device.identifier }).pipe(
          filter(device => device!=null && device.commands.length > 0)
        ).subscribe(device => {
          this.zone.run(() => {
            this.dataSource.data = device.commands
          })
        })
        break
      }
      default: break;
    }
  }

}
