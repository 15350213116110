import { Action } from '@ngrx/store';
import { Dictionary, Update } from '@ngrx/entity';
import { Device } from '../../models/device.model';
import { Asset } from 'src/app/models/asset.model';
import { LRTDataDown } from 'src/app/models/loriot-data-down.model';

export enum DeviceActionTypes {
  LoadDevices = '[Device] Load Devices',
  LoadDevicesSuccess = '[Device] Load Devices Success',
  LoadDevicesFailure = '[Device] Load Devices Failure',
  AddDevice = '[Device] Add Device',
  AddDeviceSuccess = '[Device] Add Device Success',
  AddDeviceFailure = '[Device] Add Device Failure',
  UpsertDevice = '[Device] Upsert Device',
  AddDevices = '[Device] Add Devices',
  UpsertDevices = '[Device] Upsert Devices',
  UpdateDevice = '[Device] Update Device',
  UpdateDeviceSuccess = '[Device] Update Device Success',
  UpdateDeviceFailure = '[Device] Update Device Failure',
  UpdateDevices = '[Device] Update Devices',
  DeleteDevice = '[Device] Delete Device',
  DeleteDevices = '[Device] Delete Devices',
  ClearDevices = '[Device] Clear Devices',
  ValveSwitchByKerlink = '[Device] Valve Switch by Kerlink',
  ValveSwitchByKerlinkSuccess = '[Device] Valve Switch by Kerlink Success',
  ValveSwitchByLoriot = '[Device] Valve Switch by Loriot',
  ValveSwitchByLoriotSuccess = '[Device] Valve Switch by Loriot Success',
  ValveSwitchFailure = '[Device] Valve Switch Failure',
  DownlinkWriteWakeupPeriodByLoriot = '[Device] Write Wakeup Period By Loriot',
  DownlinkWriteWakeupPeriodByKerlink = '[Device] Write Wakeup Period By Kerlink',
  DownlinkWriteWakeupPeriodByLiveObjects = '[Device] Write Wakeup Period By LiveObjects',
  DownlinkWriteWakeupPeriodSuccess = '[Device] Write Wakeup Period Success',
  DownlinkWriteWakeupPeriodFailure = '[Device] Write Wakeup Period Failure',
  LoadDownlinkCommands = '[Device] Load Downlink Commands',
  LoadDownlinkCommandsSuccess = '[Device] Load Downlink Commands Success',
  LoadDownlinkCommandsFailure = '[Device] Load Downlink Commands Failure',
}

export class LoadDevices implements Action {
  readonly type = DeviceActionTypes.LoadDevices;
  constructor(public payload: Dictionary<Asset>) {}
}

export class LoadDevicesSuccess implements Action {
  readonly type = DeviceActionTypes.LoadDevicesSuccess;
  constructor(public payload: Device[]) {}
}

export class LoadDevicesFailure implements Action {
  readonly type = DeviceActionTypes.LoadDevicesFailure;
  constructor(public payload: { error: any }) {}
}

export class AddDevice implements Action {
  readonly type = DeviceActionTypes.AddDevice;
  constructor(public payload: Device) {}
}

export class AddDeviceSuccess implements Action {
  readonly type = DeviceActionTypes.AddDeviceSuccess;
  constructor(public payload: Device) {}
}

export class AddDeviceFailure implements Action {
  readonly type = DeviceActionTypes.AddDeviceFailure;
  constructor(public payload: { error: any }) {}
}
export class UpsertDevice implements Action {
  readonly type = DeviceActionTypes.UpsertDevice;
  constructor(public payload: { device: Device }) {}
}

export class AddDevices implements Action {
  readonly type = DeviceActionTypes.AddDevices;
  constructor(public payload: { devices: Device[] }) {}
}

export class UpsertDevices implements Action {
  readonly type = DeviceActionTypes.UpsertDevices;
  constructor(public payload: { devices: Device[] }) {}
}

export class UpdateDevice implements Action {
  readonly type = DeviceActionTypes.UpdateDevice;
  constructor(public payload: Device) {}
}

export class UpdateDeviceSuccess implements Action {
  readonly type = DeviceActionTypes.UpdateDeviceSuccess;
  constructor(public payload: Update<Device>) {}
}

export class UpdateDeviceFailure implements Action {
  readonly type = DeviceActionTypes.UpdateDeviceFailure;
  constructor(public payload: { error: any }) {}
}

export class UpdateDevices implements Action {
  readonly type = DeviceActionTypes.UpdateDevices;
  constructor(public payload: { devices: Update<Device>[] }) {}
}

export class DeleteDevice implements Action {
  readonly type = DeviceActionTypes.DeleteDevice;
  constructor(public payload: { id: string }) {}
}

export class DeleteDevices implements Action {
  readonly type = DeviceActionTypes.DeleteDevices;
  constructor(public payload: { ids: string[] }) {}
}

export class ClearDevices implements Action {
  readonly type = DeviceActionTypes.ClearDevices;
}

export class ValveSwitchByKerlink implements Action {
  readonly type = DeviceActionTypes.ValveSwitchByKerlink;
  constructor(public payload: { devEui: string, switchCode: string }) {}
}

export class ValveSwitchByKerlinkSuccess implements Action {
  readonly type = DeviceActionTypes.ValveSwitchByKerlinkSuccess;
  constructor(public payload: { path: string }) {}
}

export class ValveSwitchByLoriot implements Action {
  readonly type = DeviceActionTypes.ValveSwitchByLoriot;
  constructor(public payload: { devEui: string, switchCode: string }) {}
}

export class ValveSwitchByLoriotSuccess implements Action {
  readonly type = DeviceActionTypes.ValveSwitchByLoriotSuccess;
  constructor(public payload: { ack: LRTDataDown }) {}
}

export class ValveSwitchFailure implements Action {
  readonly type = DeviceActionTypes.ValveSwitchFailure;
  constructor(public payload: { error: any }) {}
}
export class DownlinkWriteWakeupPeriodByLoriot implements Action {
  readonly type = DeviceActionTypes.DownlinkWriteWakeupPeriodByLoriot
  constructor(public payload: { device: Device, wakeUpPeriodInSeconds: string }) {}
}
export class DownlinkWriteWakeupPeriodByKerlink implements Action {
  readonly type = DeviceActionTypes.DownlinkWriteWakeupPeriodByKerlink
  constructor(public payload: { device: Device, wakeUpPeriodInSeconds: string }) {}
}

export class DownlinkWriteWakeupPeriodByLiveObjects implements Action {
  readonly type = DeviceActionTypes.DownlinkWriteWakeupPeriodByLiveObjects
  constructor(public payload: { device: Device, wakeUpPeriodInSeconds: string }) {}
}

export class DownlinkWriteWakeupPeriodSuccess implements Action {
  readonly type = DeviceActionTypes.DownlinkWriteWakeupPeriodSuccess
  constructor(public payload: Update<Device>) {}
}

export class DownlinkWriteWakeupPeriodFailure implements Action {
  readonly type = DeviceActionTypes.DownlinkWriteWakeupPeriodFailure
  constructor(public payload: { error: any }) {}
}

export class LoadDownlinkCommands implements Action {
  readonly type = DeviceActionTypes.LoadDownlinkCommands
  constructor(public payload: { device: Device, from?: string, to?: string, limit?: string }) {}
}

export class LoadDownlinkCommandsSuccess implements Action {
  readonly type = DeviceActionTypes.LoadDownlinkCommandsSuccess
  constructor(public payload: Update<Device>) {}
}

export class LoadDownlinkCommandsFailure implements Action {
  readonly type = DeviceActionTypes.LoadDownlinkCommandsFailure
  constructor(public payload: { error: any }) {}
}

export type DeviceActions =
 LoadDevices
 | LoadDevicesSuccess
 | LoadDevicesFailure
 | AddDevice
 | AddDeviceSuccess
 | AddDeviceFailure
 | UpsertDevice
 | AddDevices
 | UpsertDevices
 | UpdateDevice
 | UpdateDeviceSuccess
 | UpdateDeviceFailure
 | UpdateDevices
 | DeleteDevice
 | DeleteDevices
 | ClearDevices
 | ValveSwitchByKerlink
 | ValveSwitchByKerlinkSuccess 
 | ValveSwitchByLoriot
 | ValveSwitchByLoriotSuccess 
 | ValveSwitchFailure
 | DownlinkWriteWakeupPeriodByLoriot
 | DownlinkWriteWakeupPeriodByKerlink
 | DownlinkWriteWakeupPeriodByLiveObjects
 | DownlinkWriteWakeupPeriodSuccess
 | DownlinkWriteWakeupPeriodFailure
 | LoadDownlinkCommands
 | LoadDownlinkCommandsSuccess 
 | LoadDownlinkCommandsFailure;
