import { Component, OnInit, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import * as mainReducers from '../../reducers/index';
import { MeasurementPoint } from '../../models/measurement-point.model';
import { Device } from '../../models/device.model';

@Component({
  selector: 'app-measurement-point-dialog',
  templateUrl: './measurement-point-dialog.component.html',
  styleUrls: ['./measurement-point-dialog.component.scss']
})
export class MeasurementPointDialogComponent implements OnInit {
  measurementPointForm: FormGroup

  actionType: string = 'new'
  measurementPoint: MeasurementPoint
  devices$: Observable<Device[]>

  constructor(
    public dialogRef: MatDialogRef<MeasurementPointDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private store: Store<mainReducers.State>,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    if (this.data.action) { 
      this.actionType = this.data.action
    }

    if (this.data.measurementPoint) {
      this.measurementPointForm = this.fb.group({
        name: [this.data.measurementPoint.name, Validators.required],
        location: this.fb.group({
          latitude: [this.data.measurementPoint.latitude],
          longitude: [this.data.measurementPoint.longitude]
        })
      });
    } else {
      this.measurementPointForm = this.fb.group({
        name: ['', Validators.required],
        location: this.fb.group({
          latitude: [''],
          longitude: ['']
        })
      });
    }

    this.devices$ = this.store.select(mainReducers.selectFeatureDevicesEntitiesAsArray)
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    let measurementPoint: Partial<MeasurementPoint> = {
      name: this.measurementPointForm.value.name,
      latitude: this.measurementPointForm.value.location.latitude,
      longitude: this.measurementPointForm.value.location.longitude
    }

    let result = {
      measurementPoint: measurementPoint
    }

    if(this.actionType == 'edit') {
      measurementPoint.identifier = this.data.measurementPoint.identifier;
      let result = {measurementPoint : Object.assign(this.data.measurementPoint, measurementPoint)}
      this.dialogRef.close(result)
    } else {
      this.dialogRef.close(result)
    }
  }

  onConfirm(): void {
    let measurementPoint: MeasurementPoint = this.data.measurementPoint; 
    this.dialogRef.close({ measurementPoint: measurementPoint });
  }

}
