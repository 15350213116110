import * as moment from "moment"

export class GenericCommand {
    created: string 
    payload: string 
    status: string 
}

// Kerlink
export interface DataDown {
    id: string
    confirmed: boolean
    endDevice: EndDevice
    fPort: number 
    payload: string 
    dlParameters: DlParameter[]
    encodingType: string
    event: string 
    fCntDown: number 
    gwEui: string 
    historic: Historic[]
    nbAttempts: number 
    status: string   
    timestamp: number 
    links: Link[]
}
export interface DlParameter {
    dataRate: string 
    freg: number 
    rxWindow: string 
}
export interface EndDevice {
    devEui: string 
}
export interface Historic {
    date: number 
    event: string 
    status: string 
}
export interface Link {
    href: string 
    rel: string 
} 
// Live Objects
export class LiveObjectsCommand {
    id: string
    targetDeviceId: string
    request: LiveObjectsRequest
    status: string
    policy: LiveObjectsPolicy
    history: LivebOjectsHistory[]
    created: any
    updated: string   
    
    static formatTime(datetime: any, local: boolean): string {
        if(Array.isArray(datetime)) {
            let sss = 0
            if(datetime.length >=6 && datetime[6]) {
                sss = Number(datetime[6].toString().substring(0, 3))
                datetime[6] = sss
            }
            let created = moment(datetime).format('YYYY-MM-DD HH:mm:ss.SSS')
            if(local) {
                let d = new Date(created)
                return d.toISOString().split('T')[0] + ' ' + d.toTimeString().split(' ')[0]
            }
            return created
        } else if(typeof datetime === 'string') {
            if(local) {
                let d = new Date(datetime)
                return d.toISOString().split('T')[0] + ' ' + d.toTimeString().split(' ')[0]
            }
            return datetime
        }
        return 'Invalid datetime'
    }
}
export class LiveObjectsRequest {
    connector: string
    value: LiveObjectsRequestValue
}
export class LiveObjectsRequestValue {
    data: string
    port: string 
}
export class LiveObjectsPolicy {
    expirationInSeconds: number
    ackMode: string
    attempts: number 
}
export class LivebOjectsHistory {
    timestamp: string
    status: string 
}

